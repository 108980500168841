@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Readex+Pro&display=swap");
/* Top Navigation Bar */
.navLayoutStyle {
  left: 0px;
  width: 100%;
  position: fixed;
  z-index: 1002;
  display: flex;
  top: 0;
  justify-content: end;
  background-color: #F9FBFE;
  overflow: hidden;
  height: 70px;
}

.logoStyle {
  margin-top: 29px;
  margin-left: 29px;
  width: 227px;
  height: 43px;
}

.cursorPointer {
  cursor: pointer;
}

.userStyle {
  cursor: pointer;
  text-align: left;
  letter-spacing: 0px;
  color: #212529;
}

.secondPartStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  width: 200px;
}

.spaceBetweenSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBarLayoutIconStyle {
  cursor: pointer;
  margin-top: 47.99px;
}

.notificationTextStyle {
  font-size: 18px;
  font-weight: 600;
  margin-left: 20px;
}
.notificationDivStyle {
  width: 380px;

  margin-left: 20px;
  margin-right: 10px;
  padding-bottom: 20px;
}
.notificationHeadingStyle {
  font-size: 15px;
  font-weight: 600;
}
.notificationDetailsStyle {
  margin-top: 5px;
  font-size: 12px;
}
.notificationDateTimeStyle {
  margin-top: 4px;
  color: gray;
  font-size: 10px;
}

/* Sign Out Penal */

.container {
  padding: 3px 20px;
}
.userDiv {
  display: flex;
  justify-content: space-between;
}
.userId {
  font-size: 10px;
}
.teamMember:hover {
  background-color: #f2f4ff;
  /* color: white; */
}
@media screen and (max-width: 600px) {
  /* Top Navigation Bar */
  .navBarLayoutIconStyle {
    margin-top: 32px;
    margin-right: 10px;
    float: right;
    display: block;
  }
  .secondPartStyle {
    display: flex;
    justify-content: center;
  }
  .logoStyle {
    margin-top: 10px;
    margin-left: auto;
    width: 180px;
    height: 40px;
  }
  .navLayoutStyle {
    display: block;
    text-align: center;
  }

  .searchStyle {
    margin-top: 30px;
    width: 17px;
    height: 17px;
  }
  .notificationStyle {
    margin-top: 27.99px;
    width: 18px;
    height: 22px;
  }

  .profileStyle {
    margin-top: 16px;
    margin-right: 20px;
    width: 46px;
    height: 46px;
  }

  .userStyle {
    margin-top: 20px;
    width: 50px;
    height: 21px;
    text-align: left;
    letter-spacing: 0px;
    color: #212529;
  }
}
