@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Readex+Pro&display=swap");
/* .invTab .ant-tabs-tab-btn {
  margin-bottom: 10px !important;

  font-size: 18px;
  margin-top: 0px !important;
}

.invTab .ant-tabs-ink-bar-animated {
  background: #1f77fb !important;
  height: 4px !important;
  border-radius: 10px;
} */

.invTab .ant-tabs-nav-wrap {
  background-color: #f9fbfe !important;
  border-bottom: 1px solid lightgray !important;
  padding: 0px !important;
}

/* .invTab .ant-tabs-tab {
  padding: 0px !important;
} */

/* @media screen and (max-width: 600px) {
  .invTab .ant-tabs-tab-btn {
    margin-bottom: 5px !important;
    font-size: 12px;
    margin-right: 7px !important;
  }
  .invTab .ant-tabs-ink-bar-animated {
    height: 5px !important;
    border-radius: 10px;
    width: 24% !important;
  }
}
@media screen and (min-width: 601px) and (max-width: 768px) {
  .invTab .ant-tabs-tab-btn {
    margin-bottom: 10px !important;
    color: #212529 !important;

    font-size: 15px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-right: 40px !important;
  }
} */
