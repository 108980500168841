@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");

.ant-badge-status-text {
  font-size: 14px !important;
}

/* .ant-picker-input input::placeholder {
  color: #f0f0f0 !important;
} */

/* .ant-select-selection__placeholder {
  color: blue !important;
} */

::-webkit-scrollbar {
  width: 0px !important;
  height: 10px !important;
  /* background-color: #f8fafe !important; */
  border-radius: 50px !important;
  /* background-color: #4096ff41 !important; */
}
.upperCase {
  text-transform: uppercase;
}
.selected-row {
  background-color: #f2f4ff;
}

.headerStyle {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 20;
}
.divBtnBorder {
  border: 1px solid gray;
}
/* Application Fonts  */

.popinHeading {
  font-size: 32px;
  color: #212529;
}
/* Model Footer Button Layout */
.ModelFooterStyle {
  gap: 10px;
  display: flex;
  justify-content: end;
}
/*==================custom css for add drawer===========================*/

.InvAddDrawer .ant-drawer-header {
  padding: 8px 16px !important;
  border-bottom: 0 !important;
}

.InvAddDrawer .ant-drawer-body {
  padding: 0px 34px !important;
}

.InvAddDrawer .ant-drawer-close {
  display: none !important;
}

.InvAddDrawer .ant-drawer-title {
  font-size: 24px !important;
  font-weight: 500;
  margin-left: 17px;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.InvAddDrawer {
  position: relative;
  top: 100px;
  background-color: white !important;
}

.InvAddDrawer .ant-drawer-footer {
  border-top: none !important ;
  padding: 8px 30px !important;
}
.cursorPointer {
  cursor: pointer;
}
/*Drawer Classes For Product Category A*/

.InvAddDrawer-A .ant-drawer-footer {
  text-align: end !important;
}

/*----------------Modify State Css------------------------*/
.invFormSelect .ant-select-selector {
  border-radius: 3px !important;
}

/*==================custom css for details drawer===========================*/

.InvDetailsDrawer .ant-drawer-header {
  padding: 12px 10px !important;
  border-bottom: 0 !important;
}

.InvDetailsDrawer .ant-drawer-body {
  padding: 10px !important;
}

.InvDetailsDrawer .ant-drawer-close {
  display: none !important;
}

.InvDetailsDrawer .ant-drawer-title {
  font-size: 20px !important;
}

.InvDetailsDrawer .ant-drawer-footer {
  text-align: end !important;
}
.InvDetailsDrawer .ant-input-lg {
  border-radius: 3px !important;
}

/*====================custom css for tabs==============================*/
.InvTab .ant-tabs-tab-disabled {
  color: #212529 !important;
}
.InvTab .ant-tabs-tab {
  font-size: 16px !important;
  font-weight: 500;
}

/*==================custom css for ant table===========================*/
.InvTable .ant-table-row-expand-icon-cell {
  background-color: white !important;
}

.InvTable .ant-table-cell {
  padding: 2px 8px !important;
}

.InvTable .ant-table-container table tr th td {
  border-radius: unset !important;
}

.InvTable .ant-table-thead {
  vertical-align: top !important;
}

.InvTable .ant-table-container {
  min-height: auto !important;
  background-color: white !important;
}

.InvTable .ant-table-thead > tr > th {
  background-color: transparent !important;
}

.ant-table-thead > tr > th {
  /* background-color: transparent !important; */
  font-weight: 500 !important;
  font-size: 13px !important;
}
.InvTable .ant-pagination-options {
  left: 0px;
  position: absolute;
  margin-inline-start: 0px !important;
}

.InvTable .ant-pagination .ant-pagination-mini .ant-table-pagination {
  position: relative;
}
.InvTable .ant-table {
  background-color: transparent !important;
}

.InvTable .ant-table-row:hover td {
  background-color: #f2f4ff !important;
}
/* .InvTable .searchDivStyle {
  height: 57px;
} */
.ant-table-wrapper .ant-table {
  /* font-size: 13px !important; */
  min-height: 19vh !important;
}
.InvTable .invInput {
  border: none;
  border-bottom: 1px solid lightgrey;
  border-radius: 0px;
  padding: 0px;
  padding-top: 10px;
  font-weight: 500;
}

.InvTable .invSelect .ant-select-selector {
  border-bottom: 1px solid lightgrey !important;
  border-radius: 0px !important;
  padding: 0px !important;
  width: 120px !important;
  font-size: 12px !important;
  padding-top: 5px !important;
  height: 35px !important;
}
.InvTable .invSelectVendor .ant-select-selector {
  /* border-bottom: 1px solid lightgrey !important; */
  border-radius: 0px !important;
  padding: 0px !important;
  /* padding-top: 5px !important; */
  /* height: 35px !important; */
}
.InvTable .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  inset-inline: 0px !important;
  top: 62% !important;
  color: #c12626 !important;
}

.InvTable .ant-table-footer {
  background-color: transparent !important;
}
.InvTable .ant-pagination .ant-pagination-item {
  margin-inline-end: 0 !important;
}
.InvTable .ant-pagination .ant-pagination-prev {
  margin-inline-end: 0 !important;
}
.InvTableBtn {
  height: 26px;
  width: 80px;
  font-weight: 500;
  padding: 0 !important;
  font-size: 12px !important;
  /* text-transform: uppercase !important; */
}

/*Vendor Table Heading */

/* .InvVendorTable  .ant-table-thead > tr > td {
  color: rgb(189 189 189 / 88%) !important;
} */

.MoveManyDrawer .ant-table-cell {
  padding: 2px 4px !important;
  font-size: 12px;
}
/*==================custom css for ADD Form===========================*/
.InvAddForm .ant-form-item .ant-form-item-label > label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: black !important;
}
.InvAddForm .ant-input-lg {
  /* font-size: 14px !important; */
  border-radius: 4px !important;
  /* height: 45px !important; */
}
.InvAddForm .ant-select-selector {
  /* font-size: 14px !important; */
  border-radius: 4px !important;
  /* height: 45px !important; */
}
.InvAddForm .ant-picker {
  font-size: 14px !important;
  border-radius: 4px !important;
  /* height: 45px !important; */
}
.InvAddForm .ant-input-number {
  font-size: 14px !important;
  border-radius: 4px !important;
  /* height: 45px !important; */
}

.InvAddForm .ant-input::placeholder {
  /* font-size: 10px !important; */
  color: #dcdcdc !important;
}
.InvAddForm .ant-select-selection-placeholder {
  color: #dcdcdc !important;
}
.InvAddForm .ant-picker-input > input::placeholder {
  color: #dcdcdc !important;
}

.ant-form-item .ant-form-item-label {
  text-align: start;
}
/*==================custom css for details edit form ======================*/
.InvDForm .ant-form-item {
  margin-bottom: 12px !important ;
}
.InvDForm .ant-form-item .ant-form-item-label {
  text-align: start !important;
}
.InvDForm .ant-form-item .ant-form-item-label > label {
  /* color: #d9d9d9 !important; */
  color: rgba(0, 0, 0, 0.45) !important;
}
.InvDForm .ant-form-item-control-input-content {
  font-weight: 500 !important;
}
/* For Input group after */
.ant-input-number-group .ant-input-number-group-addon {
  background-color: rgb(255, 255, 255);
  border: 1px solid #d9d9d9;
}
/* .InvDForm .ant-input {
  font-weight: 500 !important;
  border: none !important;
  border-radius: 0% !important;
  border-bottom: 1px solid #dadbdd !important;
}
.InvDForm .ant-input-number {
  border: none !important;
  border-radius: 0% !important;
  border-bottom: 1px solid #dadbdd !important;
}
.InvDForm .ant-input-number > input {
  font-weight: 500 !important;
}
.InvDForm .ant-input-number .ant-input-number-input {
  font-weight: 500 !important;
}
.InvDForm .ant-select {
  border: none !important;
  border-radius: 0% !important;
  border-bottom: 1px solid #dadbdd !important;
}
.InvDForm .ant-picker {
  border: none !important;
  border-radius: 0% !important;
  border-bottom: 1px solid #dadbdd !important;
}
.InvDForm .ant-picker-input > input {
  font-weight: 500 !important;
}
.InvDForm .ant-select-selection-item {
  font-weight: 500 !important;
}
.InvDForm .ant-select-selector {
  border: none !important;
  border-radius: 0% !important;
} */
.ant-form-item-explain {
  font-size: 10px !important;
  font-weight: 500 !important;
}
/*==================custom css for Modal==============================*/
.InvModal .ant-modal-content {
  border-radius: 0px !important;
}
.InvModal .ant-modal-title {
  font-size: 24px !important;
}
.InvModal .ant-divider {
  margin: 0px 0 20px 0 !important;
  border-bottom: 1px solid #d2d3d4 !important;
}
.InvModal_modifyState .ant-modal-content {
  border-radius: 0px !important;
}
.InvModal_modifyState .ant-modal-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 30px !important;
}

/*==================custom css for button==============================*/
.Inv-SaveBtn {
  margin: 5px;
  /* height: 35px !important; */
  padding: 4px 38px !important;
  /* border-radius: 22px !important; */
}
.Inv-SaveAndNewBtn {
  margin: 5px;
  /* height: 35px !important; */
  padding: 4px 16px !important;
  /* border-radius: 22px !important; */
}

.Inv-CancelBtn {
  margin: 5px;
  /* height: 35px !important; */
  padding: 3px 30px !important;
  /* border-radius: 22px !important; */
}
.Inv-CancelBtn:hover {
  opacity: 0.7;
}

/*============== Digital Product Css====================*/

.invRowStyle {
  margin-top: 0px;
  margin-bottom: 30px;
}
.addBtnStyle {
  font-size: 16px;
  margin-top: 49px;
}
/* .deleteBtnStyle {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 12.7px 0px;
  background-color: white;
  width: 50px;
  border: 1px solid lightgray;
  padding-left: 18px;
  cursor: pointer;
} */
.deleteBtnStyle {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 9.7px 0px;
  background-color: white;
  width: 50px;
  border: 1px solid lightgray;
  padding-left: 15px;
  padding-top: 10px;
  cursor: pointer;
}
.labelStyle {
  font-size: 15px;
  font-weight: 500;
  padding: 12px 0px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgrey;
}
.addedNewRowStyle {
  margin-bottom: 10px;
}

.addedNewRowStyle > #col1 > div {
  border-left: 1px solid lightgrey;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 12px;
}

/* Overwrite Classes */

.invTabProduct .ant-tabs-tab-btn {
  margin-bottom: 10px !important;

  font-size: 18px;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 50px !important;
  font-weight: 500 !important;
}

.invTabProduct .ant-tabs-ink-bar-animated {
  height: 5px !important;
  border-radius: 10px;
  /* width: 26% !important; */
}

.invTabProduct .ant-tabs-nav-wrap {
  background-color: white !important;
  border-bottom: 1px solid lightgray !important;
  padding: 0px !important;
}

.invTabProduct .ant-tabs-tab {
  padding: 0px !important;
}

.invSelectStyle .ant-select-selector {
  height: 50px !important;
}

/*===================== ParentCategory-A Css===================== */
.avatarDivStyle {
  margin: 13px 0px;
  margin-left: 10px;
}
.searchDivStyle .ant-input-group-wrapper {
  width: auto !important;
}
.InvSearchInput .ant-btn-default {
  background-color: transparent !important;
  border-color: transparent !important ;
  box-shadow: none !important;
}
.InvSearchInput .ant-input-affix-wrapper > input.ant-input {
  font-weight: 500 !important;
  /* font-size: 14px !important; */
}
.avatarStyle {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 1px solid #cfc3c3;
  cursor: pointer;
  text-align: center;
  padding: 1px 0px;
  font-size: 10px;
  font-weight: 600;
  box-sizing: border-box;
  display: inline-block;
}
.popoverStyle {
  border-radius: 100%;
  border: 1px solid lightgray;
  background-color: white;
  height: 25px;
  width: 25px;
  font-size: 10px;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.avatarSpanDivStyle {
  margin-top: 5px;
}

.modifyPopoverHeading {
  font-size: 12px;
  font-weight: 500;
}

.modifyPopoverData {
  font-size: 14px;
  margin: 10px;
}

/*=========================== Summary Css ================================= */
.userName {
  font-size: 20px;
  color: #212529;
  font-weight: 500;
}
/* .plus {
}
.minus {
}
.statements {
} */
/*=========================== Reports Css ================================= */
.linkButton {
  padding: 0px !important;
  border-radius: 0% !important;
  border-bottom: 1px dashed;
  padding-right: 25px !important;
  color: #949698;
}
/*======================Required shortcuts css==============================*/
.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}
.search-input {
  /* temporary */
  margin-bottom: 8px;
  margin-top: 8px;
  border-bottom: 1px solid #dfe9f7 !important;
  width: 50%;
}
.userSelectNone {
  user-select: none;
}
.pre-style {
  white-space: pre-wrap;
  margin: 0 !important;

  font-weight: 500 !important;
  color: #949698 !important;
}

/*=======custom height-width css=========*/
.h100vh {
  height: 100vh;
}

.w50 {
  width: 50%;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

/*=======custom text css=========*/

.text-center {
  text-align: center;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-dark {
  color: black !important;
}

.text-light {
  color: #949698 !important;
}

/* .add .ant-card-body {
 padding: 20px 24px !important;
} */
.customCollapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px !important;
}
/*=======custom border css=========*/
.border {
  border: 1px solid #dee2e6;
}
.purchaseC .ant-card-body {
  padding: 24px 11px !important;
}
.borderlight {
  border: 1px solid #dfe9f7;
}

.border-bottom {
  border-bottom: 1px solid #dfe9f7 !important;
}

.border-none {
  border: none;
}
.purchaseC .ant-card-body {
  padding: 24px 11px !important;
}
/*=======custom font-size css===========*/
.font12 {
  font-size: 12px;
}
.font13 {
  font-size: 13px !important;
}
.fontS {
  font-size: 10px;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font21 {
  font-size: 21px;
}

/*=======custom font-weight css=========*/

.fw600 {
  font-weight: 600;
}

.fw500 {
  font-weight: 500;
}

/*=======custom display css=========*/

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

/*=======custom padding css=========*/
.p-5 {
  padding: 48px;
}

.px-5 {
  padding-left: 48px;
  padding-right: 48px;
}

.py-5 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.pb-5 {
  padding-bottom: 48px;
}

.pt-5 {
  padding-top: 48px;
}

.ps-5 {
  padding-left: 48px;
}

.pe-5 {
  padding-right: 48px;
}

.p-4 {
  padding: 24px;
}

.py-4 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.px-4 {
  padding-left: 24px;
  padding-right: 24px;
}

.pt-4 {
  padding-top: 24px;
}

.pb-4 {
  padding-bottom: 24px;
}

.ps-4 {
  padding-left: 24px;
}

.pe-4 {
  padding-right: 24px;
}

.p-3 {
  padding: 16px;
}

.py-3 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.px-3 {
  padding-left: 16px;
  padding-right: 16px;
}

.pt-3 {
  padding-top: 16px;
}

.pb-3 {
  padding-bottom: 16px;
}

.ps-3 {
  padding-left: 16px;
}

.pe-3 {
  padding-right: 16px;
}

.p-2 {
  padding: 8px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.pt-2 {
  padding-top: 8px;
}

.pb-2 {
  padding-bottom: 8px;
}

.ps-2 {
  padding-left: 8px;
}

.pe-2 {
  padding-right: 8px;
}

.p-1 {
  padding: 4px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.pt-1 {
  padding-top: 4px;
}

.pb-1 {
  padding-bottom: 4px;
}

.ps-1 {
  padding-left: 4px;
}

.pe-1 {
  padding-right: 4px;
}

.p-0 {
  padding: 0px !important;
}

/*=======custom margin css=========*/

.m-5 {
  margin: 48px;
}

.mx-5 {
  margin-left: 48px;
  margin-right: 48px;
}

.my-5 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.mb-5 {
  margin-bottom: 48px;
}

.mt-5 {
  margin-top: 48px;
}

.ms-5 {
  margin-left: 48px;
}

.me-5 {
  margin-right: 48px;
}

.m-4 {
  margin: 24px;
}

.mx-4 {
  margin-left: 24px;
  margin-right: 24px;
}

.my-4 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mb-4 {
  margin-bottom: 24px;
}

.mt-4 {
  margin-top: 24px;
}

.ms-4 {
  margin-left: 24px;
}

.me-4 {
  margin-right: 24px;
}

.m-3 {
  margin: 16px;
}

.mx-3 {
  margin-left: 16px;
  margin-right: 16px;
}

.my-3 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mt-3 {
  margin-top: 16px;
}

.mb-3 {
  margin-bottom: 16px;
}

.ms-3 {
  margin-left: 16px;
}

.me-3 {
  margin-right: 16px;
}

.m-2 {
  margin: 8px;
}

.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mt-2 {
  margin-top: 8px;
}

.mb-2 {
  margin-bottom: 8px;
}

.ms-2 {
  margin-left: 8px;
}

.me-2 {
  margin-right: 8px;
}

.m-1 {
  margin: 4px;
}

.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}

.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.ms-1 {
  margin-left: 4px;
}

.me-1 {
  margin-right: 4px;
}

.mt-1 {
  margin-top: 4px;
}

.mb-1 {
  margin-bottom: 4px;
}

.m-0 {
  margin: 0px !important;
}
.ml {
  margin-left: 8px;
}

.mx-auto {
  margin: 0 auto !important;
}
.my-auto {
  margin: auto 0 !important;
}

/*For Text Align*/
.textRight {
  text-align: right;
}
/*==================drawer responsive css===========================*/

@media (max-width: 575px) {
  .ant-drawer-content-wrapper {
    width: 300px !important;
  }
  .InvAddDrawer {
    top: 140px;
  }
}
.ant-card .ant-card-grid {
  padding: 8px 20px 3px 20px;
}

/* .ant-form-item {
  margin: 5px 0px !important;
} */
/* .ant-card {
  margin-bottom: 15px !important;
} */

.pcConfigTree .ant-tree-list-scrollbar-thumb {
  width: 5px !important;
  /* background-color: #4096ff41 !important; */
}
.Report2 .ant-table-cell {
  padding: 2px 8px !important;
  vertical-align: top !important;
}
