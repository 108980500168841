@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Readex+Pro&display=swap");

/* Section 1 Css */

/* Category Table Css  */
.headerColumnStyle {
  color: grey;

  font-size: 15px;
  font-weight: 100;
  padding-left: 10px;
}

.categoriesStyle {
  color: #212529;

  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
}

.headingStyle {
  color: #212529;

  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.instockStyle {
  color: #212529;

  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
}

.inuseStyle {
  color: #212529;

  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
}

.disposedStyle {
  color: #212529;

  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
}

.inserviceStyle {
  color: #212529;

  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
}

/* Override Classess of Table */

.invMainTable .ant-table {
  background-color: white !important;
  border: none !important;
  box-shadow: 0px 0px 10px 0.3px rgba(216, 220, 230, 1) !important;
}
.invMainTable .ant-table-footer {
  background-color: white !important;
}
.invMainTable .ant-table-thead > tr > th {
  background-color: white !important;
}
.invMainTable .ant-table-row:hover td {
  background-color: white !important;
}

.invetoryReportStyle {
  cursor: pointer;
  float: right;

  font-size: 14px;
  font-weight: 100;
  margin-right: 20px;
  border-bottom: 1px solid black;
  margin-top: 10px;
}

.invMainTableFooter {
  cursor: pointer;
  color: #1f77fb;

  font-size: 15px;
  font-weight: 500;
}

.invMainTableParentDivStyle {
  display: flex;
  justify-content: center;
}

.invMainTableFooterImgStyle {
  cursor: pointer;
  padding-left: 8px;
  width: 10px;
  height: 10px;
  margin-top: 2px;
}

/* Media Query For 768px for Main Table */

@media screen and (min-width: 601px) and (max-width: 768px) {
  .headerColumnStyle {
    font-size: 14px;
    padding-left: 8px;
  }

  .categoriesStyle {
    font-size: 14px;
    padding-left: 8px;
  }

  .instockStyle {
    font-size: 12px;
    padding-left: 8px;
  }

  .inuseStyle {
    font-size: 12px;
    padding-left: 8px;
  }

  .disposedStyle {
    font-size: 12px;
    padding-left: 8px;
  }

  .inserviceStyle {
    font-size: 12px;
    padding-left: 8px;
  }
}
/* Media Query For px for Main Table */
@media screen and (max-width: 599px) {
  .headingStyle {
    font-size: 15px;
    font-weight: 500;
  }

  .headerColumnStyle {
    color: black;
    font-size: 10px;
    padding-left: 0px;
  }

  .categoriesStyle {
    font-size: 11px;
    padding-left: 0px;
    font-weight: 500;
  }

  .instockStyle {
    font-size: 10px;
    padding-left: 0px;
    font-weight: 500;
  }

  .inuseStyle {
    font-size: 10px;
    padding-left: 0px;
    font-weight: 500;
  }

  .disposedStyle {
    font-size: 10px;
    padding-left: 0px;
    font-weight: 500;
  }

  .inserviceStyle {
    font-size: 10px;
    padding-left: 0px;
    font-weight: 500;
  }
  .invetoryReportStyle {
    display: none;
  }
}

/* Section 2 Css */
.section2RowStye {
  margin-top: 80px;
  /* border: 2px solid red; */
  /* background: radial-gradient(black, transparent); */
  margin-bottom: 80px;
}
.productDetailsDivStyle {
  margin-top: 20px;
  padding: 20px 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0.3px rgba(216, 220, 230, 1);
}

.productDetailsHeadingStyle {
  font-size: 18px;
  font-weight: 600;
}

.productDetailsDateTimeStyle {
  color: gray;

  font-size: 13px;
  font-weight: 400;
}

.loextoDivStyle {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  margin-bottom: 5px;
  cursor: pointer;
  align-items: center;
}

.loextoSelected {
  background-color: #212529;
  color: white;
  border-radius: 10px;
}

.productloextoTextStyle {
  font-size: 15px;
  font-weight: 300;
}

.productloextoDataStyle {
  font-size: 20px;
  font-weight: 600;
  align-items: center;
  margin-right: 20px;
}

.loexTableDivStyle {
  margin-top: 69px;
}

.loexLinkStyle {
  float: right;
  margin-right: 15px;
  margin-top: 10px;

  font-size: 14px;
  font-weight: 100;
  border-bottom: 1px solid black;
  cursor: pointer;
}

.topPurchaseStockInnerDivStyle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.topPurchaseStockHeading {
  font-size: 18px;
  font-weight: 600;
}
.topproductStockDateTimeStyle {
  color: gray;

  font-size: 13px;
  font-weight: 400;
}
.invDashboardSelect {
  width: 130px;
  font-size: 100px;
}
.topPurchaseOuterDivStyle {
  margin-left: 10px;
}
.numberofProductTableDivStyle {
  margin-top: 22px;
}

.section3InnerDivStyle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.row4Style {
  margin-top: 40px;
}
