@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* Sub Top Navigation Bar */
.sideBarMenuStyle {
  position: fixed;
  top: 62px;
  z-index: 1002;
  height: 38px;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

/* Side Bar Menu */
.sideMenuStyle {
  position: fixed;
  top: 73px;
  width: 220px;
  height: calc(100vh - 120px);
  overflow-y: auto;
  transition-duration: 0.2s;
}

.sideMenuStyleWithCollapse {
  position: fixed;
  top: 73px;
  width: 70px;
  height: calc(100vh - 90px);
  height: calc(100vh - 120px);
  transition-duration: 0.2s;
}

/* Menu Icon */

.menuIconStyle {
  padding: 8px;
  margin-right: 5px;
  border-radius: 7px;
}

.menuIconProductStyle {
  padding: 2px;
  margin-right: 12px;
  border-radius: 7px;
  width: 23px;
}

.subHeadingStyle {
  font-size: 25px;
  font-weight: 500;
  margin-left: 33px;
}

.sliderSubMenuFontStyle {
  font-size: 13px;
  font-weight: 400;
  margin-left: 20px;
  user-select: none;
}

.sliderSubMenuWithIconStyle {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 400;
  margin-left: 20px;
}

@media screen and (max-width: 600px) {
  /* Sub Top Navigation Bar */
  .sideBarMenuStyle {
    top: 118px;
    height: 25px;
  }
  /* Side Bar Menu */
  .sideMenuStyle,
  .sideMenuStyleWithCollapse {
    top: 140px;
  }
}

/* Override classess */

/* Divider */
.ant-menu-item-divider {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0px !important;
  background-color: #ffffff4d !important;
}

.invMenuStyle .ant-menu-item {
  border-radius: 0px 5px 5px 0px !important;
  font-size: 15px;
  font-weight: 100;
  margin-bottom: 20px !important;
}

.invMenuStyle .ant-menu-item:hover {
  border-radius: 0px 5px 5px 0px !important;
}

.invMenuStyle .ant-menu-item-selected {
  color: white !important;
  /* background-color: #1f77fb !important; */
  /* border-radius: 0px !important; */
}

.invMenuStyle .ant-menu-submenu-inline {
  margin-bottom: 20px !important;
}

.invMenuStyle .ant-menu-sub {
  margin-bottom: 20px !important;
}
.invMenuStyle .ant-menu-submenu-title {
  font-size: 14px;
  font-weight: 400;
}

.invSliderIcon .ant-layout-sider-zero-width-trigger-left {
  background-color: transparent !important;
  color: transparent !important;
  background: url("/src/CapsitechInventoryComponents/Assets/images/t2.png")
    no-repeat !important;
  border: 0px solid transparent !important;
}

.invMenuStyle .ant-layout-sider-collapsed {
  background-color: green !important;
}

@media screen and (max-width: 600px) {
  .invSliderIcon .ant-layout-sider-zero-width-trigger-left {
    height: 25px !important;
  }

  .subHeadingStyle {
    font-size: 15px;
    font-weight: 600;
    margin-left: 33px;
    margin-top: 0px;
  }
}
