@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Readex+Pro&display=swap");

.cardStyle {
  width: 165px;
  height: 105px;
  margin-top: 35px;
  /* border: 0.5px solid #d2d3d4; */
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
  margin-left: 4px;
  margin-right: 20px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.cardImgStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.cardStyle:hover {
  border: 0.5px solid #212529;
}
.cardSubHeadingStyle {
  margin-top: 15px;
  text-align: center;

  font-size: 14px;
  font-weight: 500;
  margin-left: -22px;
}

.inventoryExpensesHeadingStyle {
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 22px;

  color: #212529;
  font-weight: 500;
}

.chartStyle {
  /* border: 2px solid tomato; */
  padding: 0px 10px;
  height: 100%;
  max-width: calc(100vw - 820px);
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-right: -25px;
}
.chartDiv {
  /* border: 2px solid lightgray; */
}

.secondDivStyle {
  /* border: 2px solid black; */
  margin-left: -5px;
  padding: 0px 45px;
}

.divsubHeadingStyle {
  font-size: 16px;
  color: #abadae;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 8px;
  width: 130px;
  margin: 0px 3px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  padding: 5px 0px;
}

.divsubHeadingStyle:hover {
  box-shadow: -1px 2px 7px -3px rgba(0, 0, 0, 0.75);
}

.wmyDivStyle {
  display: flex;
  background-color: white;
  padding-bottom: 12px;

  justify-content: space-between;
}

.rowStyle {
  background-color: white;
  border-radius: 13px;
}

.divsubHeadingSelected {
  color: white !important;
  background-color: #212529 !important;
}
/* Override Ant Table Class */

.invDashTable .ant-table {
  color: #212529 !important;

  font-size: 14px !important;
  font-weight: 500 !important;
  border: 1px solid lightgray !important;
}

.invDashTable .ant-table-row {
  background-color: white !important;
  border: none !important;
}

.invDashTable .ant-table-wrapper {
  text-align: center !important;
}

.invDashTable .ant-table-tbody > tr > td {
  /* border: transparent !important; */
}

.invDashTable .ant-table-thead > tr > th {
  font-size: 13px !important;

  font-weight: lighter !important;
  color: #989a9c !important;
  background-color: #f5f4f4 !important;
  padding: 10px 15px !important;
}

.invDashTable .ant-table-footer {
  padding: 0px !important;
}

.invDashTable .ant-table-tbody > tr:last-child {
  border: 5px solid black !important;
}

@media screen and (max-width: 600px) {
  .cardStyle {
    width: 140px;
    height: 127px;
    margin-right: 10px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }

  .cardImgStyle {
    height: 90px;
  }

  .cardSubHeadingStyle {
    margin-top: 3px;
    font-size: 10px;
    font-weight: 500;
  }

  .divsubHeadingStyle {
    font-size: 13px;
    width: 130px;
    margin: 4px 2px;
    font-weight: 400;
    padding: 4px 20px;
    background-color: white;
  }

  .inventoryExpensesHeadingStyle {
    margin-top: 30px;
    margin-bottom: 0px;
    font-size: 19px;
    font-weight: 500;
  }

  .secondDivStyle {
    margin-top: 10px;
    padding: 1px 5px;
  }

  .invDashTable .ant-table {
    font-size: 12px !important;
    font-weight: 400 !important;
    padding: 0px !important;
    border: 0.25px solid lightgray !important;
  }

  /* .invDashTable .ant-table-row {
    background-color: transparent !important;
    background-color: #f9f8f4 !important;
  } */

  .invDashTable .ant-table-thead > tr > th {
    background-color: #f9f8f4 !important;
  }

  .wmyDivStyle {
    background-color: transparent;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  .rowStyle {
    background-color: transparent;
    border-radius: 13px;
    padding: 5px 0px;
  }
}

@media screen and (min-width: 1681px) {
  .chartStyle {
    max-width: calc(100vw - 50vw);
  }
}
@media screen and (max-width: 1679px) and (min-width: 767px) {
  .chartStyle {
    max-width: calc(100vw - 62vw);
  }
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
  .chartStyle {
    max-width: calc(100vw - 33vw);
  }
}
@media screen and (max-width: 768px) and (min-width: 730px) {
  .chartStyle {
    max-width: calc(100vw - 41vw);
  }
}
@media screen and (max-width: 729px) and (min-width: 601px) {
  .chartStyle {
    max-width: calc(100vw - 45vw);
  }
}
